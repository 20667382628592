@charset "utf-8";

.debug-corner-button {
    float: right;
}

#debug-view {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
}
