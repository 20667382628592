@charset "utf-8";

$orange: #e68659;
$link: $orange;
$link-hover: #363636;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button.link {
  appearance: unset;
  background-color: transparent;
  color: $link;
  border: none;
  padding: 0;
  font-size: 1em;
  font-weight: inherit;
  cursor: pointer;

  &:hover {
    color: $link-hover;
  }
}
