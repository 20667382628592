@charset "utf-8";

$orange: #e68659;
$button-active: #f5f5f5;

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

img.main-logo {
    width: 50vw;
    height: 50vh;
}

button.g-button {
    display: inline-block;
    background: white;
    // color: #444;
    width: 240px;
    height: 46px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;

    &:hover {
        cursor: pointer;
        box-shadow: 1px 1px 1px $orange;
    }

    &:active {
        cursor: pointer;
        background: $button-active;
        box-shadow: 0 0 0;
    }

    span.label {
        font-family: serif;
        font-weight: normal;
    }
    span.icon {
        background: url("./images/g-logo-transparent.png") transparent 5px 50% no-repeat;
        background-size: 32px;
        display: inline-block;
        vertical-align: middle;
        width: 42px;
        height: 42px;
    }
    span.buttonText {
        display: inline-block;
        vertical-align: middle;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 16px;
        font-weight: bold;
        /* Use the Roboto font that is loaded in the <head> */
        font-family: "Roboto", sans-serif;
    }
}

.alert {
    margin-top: 1em;
    color: red;
}
